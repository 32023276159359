import {
    Check,
    ConfirmModal,
    CustomButton,
    TitleAndTable,
    WarningModal,
} from "components/Common"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import ProfileModal from "./Modal/ProfileModal"
import ProfileTable from "./ProfileTable"

import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler,
    trimObjectValues,
} from "helpers/utilities"

import {
    addNewProfileTest,
    addNewSubProfile,
    addNewTestProfile,
    deleteTestProfiles,
    getTestProfileDetail,
    getTestProfileTests,
    getTestProfiles,
    resetTestProfileSearchQuery,
    saveDisplayOrder,
    saveLengthParameter,
    saveProfileTests,
    saveTestProfileGroups,
    updateTestProfile,
    deleteSubProfiles,
    editSubProfile,
    deleteProfileVendor,
    updateInfoProfile
} from "store/laboratory/profile/actions"

//i18n
import { ModuleIds, permissionType } from "constant"
import { getUrlParamByKey, getUrlParams, insertUrlParam } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import QuickAddModal from "./Modal/QuickAddModal"
import AddProfileSend from "./Modal/AddProfileSend"
import { updateInfoTest } from "store/actions"

const RESOURCE = ModuleIds.Test

const Profiles = ({
    history,
    testProfiles,
    paging,
    onGetTestProfiles,
    onAddNewTestProfile,
    onUpdateTestProfile,
    onDeleteTestProfile,
    onResetTestProfileSearchQuery,
    onGetTestProfileDetail,
    testProfile,
    loadingTestProfiles,
    updatedTestProfileTime,
    onAddNewProfileTest,
    onGetTestProfileTests,
    testProfileTests,
    onSaveProfileTests,
    onDisplayOrderChange,
    onAddNewSubProfile,
    onSaveSubProfiles,
    testProfileGroups,
    onDeleteProfileVendor,
    updateInfoProfile,
    userPermissions,
    t
}) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [isClone, setIsClone] = useState(false)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const [selectQuickaddModal, setselectQuickaddModal] = useState(false)
    const [addProfileSendModal, setAddProfileSendModal] = useState(false)
    const [selectedEditItem, setSelectedEditItem] = useState({});
    const [modelQuick, setModelQuick] = useState({})
    const [defaultTestProfiles, setDefaultTestProfiles] = useState([])
    const [defaultSubProfiles, setDefaultSubProfiles] = useState([])
    const [confirmVendorModal, setConfirmVendorModal] = useState(false)
    const [vendorDelete, setVendorelete] = useState({})
    const formEl = useRef(null)

    const toggle = () => {
        setModal(prev => !prev)
        fetchTestProfileWithPage()
    }

    const addProfileClicks = () => {
        setIsEdit(false)
        setIsClone(false)
        toggle()
    }

    /**
     * Handling submit Profile on Profile form
     */
    const handleValidSubmit = (e, values, type) => {
        values.type = type
        values.displayOrder = values.displayOrder || 0;
        if (isEdit) {
            values.type = type
            values.status = values.profileStatus
            delete values.profileStatus
            onUpdateTestProfile({ testProfile: trimObjectValues(values), callback: toggle })
        } else {
            values.type = type
            const newProfile = {
                ...values,
                status: !!values["profileStatus"],
            }
            delete newProfile.profileStatus
            onAddNewTestProfile({ testProfile: trimObjectValues(newProfile), history })
        }
    }

    const onSaveTest = (data) => {
        let newProfileTests = JSON.parse(JSON.stringify(data))
        newProfileTests = newProfileTests.map(field => {
            if (field.new) {
                delete field.new
                delete field.id
            }
            return field
        })
        onSaveProfileTests({ profileId: selectedEditItem.id, testProfileTests: newProfileTests })
    }

    const handleAddListTestSubmit = async values => {
        if (values.type === 0) {
            const newProfileTest = {
                profileId: Number(selectedEditItem.id),
                testId: values.id,
                testName: values.testName,
                remark: values.remark,
                displayOrder: defaultTestProfiles?.length || 0,
                testCode: values.testCode,
            }
            if (selectQuickaddModal) {
                onAddNewProfileTest({
                    testProfileTest: newProfileTest,
                    callback: onSaveTest,
                })
            }
        }
        if (values.type === 1) {
            const newSubProfile = {
                groupId: Number(selectedEditItem.id),
                profileId: values.id,
                profileName: values.profileName,
                remark: values.remark,
                displayOrder: defaultSubProfiles?.length || 0,
            }
            if (selectQuickaddModal) {
                onAddNewSubProfile({
                    testProfileGroup: newSubProfile,
                    callback: onSaveClick,
                })
            }
        }
    }
    const onSaveClick = data => {
        let newSubProfiles = JSON.parse(JSON.stringify(data))
        newSubProfiles = newSubProfiles.map(field => {
            if (field.new) {
                delete field.new
                delete field.id
            }
            return field
        })
        onSaveSubProfiles({
            profileId: selectedEditItem.id,
            testProfileGroups: newSubProfiles,
        })
    }

    const onCloneHandler = (param) => {
        if (param) setRow(param)
        const id = param.id || row?.id
        if (id) {
            onGetTestProfileDetail(id)
            setIsEdit(false)
            setIsClone(true)
            toggle()
        } else setWarningModal(true)
    }

    const onEditHandler = (e, paramId) => {
        const id = paramId || row?.id
        if (id) {
            onGetTestProfileDetail(id)
            setIsEdit(true)
            toggle()
        } else setWarningModal(true)
    }

    const resetState = () => {
        setRows([])
        setRow({})
    }

    const onDeleteToggleHandler = (e, param) => {
        onDeleteToggle({
            rows,
            row: param || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete,
        })
        if (confirmModal) setRowDelete({})
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteTestProfile({ testProfiles: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowsState => {
        onDeleteTestProfile({
            testProfiles: rowsState,
            callback: () => {
                setRows(prev => prev.filter(x => x.id !== rowDelete.id))
            },
        })
        setRowDelete({})
        setRow({})
    }

    const onDeleteTestProfileHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        setConfirmModal(false)
    }

    /**Get selected row and set to state
     *
     */
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onGetTestProfileList = (payload) => {
        // insertUrlParam(payload)
        payload.isNotReady = false
        onGetTestProfiles(payload)
    }

    const fetchTestProfiles = () => {
        onGetTestProfileList({ page: 1 })
    }

    /** Table methods */
    const onRefreshHandler = () => {
        resetState()
        fetchTestProfiles()
    }

    const onSearch = searchText => {
        onGetTestProfileList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetTestProfileList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetTestProfileList({ page })
    }

    const onSubmitFilter = values => {
        onGetTestProfileList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetTestProfileList({ page: 1, sort: sortString })
    }

    const onAddTestList = (data) => {
        setselectQuickaddModal(true)
        setSelectedEditItem(data)
        onGetTestProfileTests(data.id)
        setModelQuick({
            search: "",
            category: "",
            type: "",
            sampleType: data?.sampleType
        })
    }

    const onDisplayOrderHandler = (item, value) => {
        onDisplayOrderChange({ id: item.id, displayOrder: value || 0 }, () => {
            fetchTestProfileWithPage()
        })
    }

    /**-----CYCLE------ */
    useEffect(() => {
        onResetTestProfileSearchQuery()
    }, [])

    const fetchTestProfileWithPage = () => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetTestProfileList(params)
        } else {
            fetchTestProfiles()
        }
    }

    useEffect(() => {
        fetchTestProfileWithPage()
    }, [])

    useEffect(() => {
        setDefaultTestProfiles(testProfileTests)
    }, [testProfileTests])

    useEffect(() => {
        setDefaultSubProfiles(testProfileGroups)
        return () => { }
    }, [testProfileGroups])

    const onAddProfileSend = (data) => {
        setAddProfileSendModal(true)
        setSelectedEditItem([data])
    }

    const onAddTestSend = () => {
        setAddProfileSendModal(true)
        setSelectedEditItem(rows)
    }

    const onDeleteVendorToggleHandler = (test) => {
        setConfirmVendorModal(state => !state)
        setVendorelete(test)
    }

    const onDeleteVendor = (data) => {
        onDeleteProfileVendor([data], () => {
            onRefreshHandler()
            setConfirmVendorModal(state => !state)
        })
    }

    const onInfoProfieleChange = (item) => {
        updateInfoProfile(item, () => {
            fetchTestProfileWithPage()
        })
    }

    return (
        <React.Fragment>
            {/* Body */}
            <TitleAndTable
                table={() => (
                    <ProfileTable
                        testProfiles={testProfiles}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={onEditHandler}
                        onDelete={onDeleteToggleHandler}
                        onClone={onCloneHandler}
                        loading={loadingTestProfiles}
                        updatedTime={updatedTestProfileTime}
                        onAddTestList={onAddTestList}
                        onDisplayOrderChange={onDisplayOrderHandler}
                        onAddProfileSend={onAddProfileSend}
                        onDeleteVendor={onDeleteVendorToggleHandler}
                        onInfoProfieleChange={onInfoProfieleChange}
                        userPermissions={userPermissions}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (
                    <>
                        <Check permission={permissionType.C} resource={RESOURCE}>
                            <CustomButton color="primary" onClick={addProfileClicks} outline>
                                {t("Add Profile")}
                            </CustomButton>
                        </Check>
                        <Check permission={permissionType.ProfileSendUpdate} resource={RESOURCE} >
                            <CustomButton color="primary" onClick={onAddTestSend} outline>
                                {t("profilePage:Add profile send")}
                            </CustomButton>
                        </Check>
                        <Check permission={permissionType.D} resource={RESOURCE}>
                            <CustomButton
                                color="primary"
                                onClick={e => {
                                    onDeleteToggleHandler(e, rows.id)
                                }}
                                outline
                            >
                                {t("common:Delete")}
                            </CustomButton>
                        </Check>
                    </>
                )}
                onEdit={onEditHandler}
                onDelete={onDeleteToggleHandler}
                onClone={onCloneHandler}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("Profiles")}
                subtitle={t("Profile List")}
            />

            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Profile")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteTestProfileHandler}
            />
            <ProfileModal
                formEl={formEl}
                modal={modal}
                isEdit={isEdit}
                onValidSubmit={handleValidSubmit}
                toggle={toggle}
                data={!isEdit ? (isClone ? row : {}) : testProfile}
            />
            <QuickAddModal
                modal={selectQuickaddModal}
                toggle={() => setselectQuickaddModal(!selectQuickaddModal)}
                onSubmit={handleAddListTestSubmit}
                model={modelQuick}
                TestDefault={defaultTestProfiles}
                setModel={setModelQuick}
                selectedRow={selectedEditItem}
                resetModel={() => {
                    setModelQuick({
                        search: selectedEditItem?.code,
                        category: "",
                        type: "",
                        sampleType: selectedEditItem?.sampleType
                    })
                }}
            />
            <AddProfileSend
                modal={addProfileSendModal}
                toggle={() => {
                    setAddProfileSendModal(!addProfileSendModal)
                    fetchTestProfiles()
                }}
                data={selectedEditItem}
            />
            <ConfirmModal
                modal={confirmVendorModal}
                title={`${t("common:Delete")}`}
                message={t("Delete profile send")}
                onToggle={onDeleteVendorToggleHandler}
                onDelete={() => onDeleteVendor(vendorDelete)}
            />
        </React.Fragment>
    )
}

Profiles.propTypes = {
    testProfiles: PropTypes.array,
    testProfileGroups: PropTypes.array,
    onGetTestProfileDetail: PropTypes.func,
    onGetTestProfiles: PropTypes.func,
    onAddNewTestProfile: PropTypes.func,
    onUpdateTestProfile: PropTypes.func,
    onDeleteTestProfile: PropTypes.func,
    onResetTestProfileSearchQuery: PropTypes.func,
    paging: PropTypes.object,
    selectedRow: PropTypes.object,
    loadingTestProfiles: PropTypes.bool,
    updatedTestProfileTime: PropTypes.any,
    onSaveSubProfiles: PropTypes.func,
    onDeleteSubProfiles: PropTypes.func,
    onUpdateSubProfile: PropTypes.func,
    onAddNewSubProfile: PropTypes.func,
    onEditSubProfile: PropTypes.func,
    t: PropTypes.any,
}

const mapStateToProps = ({ testProfile, Authorization }) => {
    return ({
        testProfileTests: testProfile.testProfileTests,
        testProfiles: testProfile.testProfiles,
        testProfile: testProfile.testProfile,
        testProfileGroups: testProfile.testProfileGroups,
        paging: testProfile.paging,
        loadingTestProfiles: testProfile.loadingTestProfile,
        updatedTestProfileTime: testProfile.updateTestProfileTime,
        userPermissions: Authorization.permissions,
    })
}

const mapDispatchToProps = dispatch => ({
    onGetTestProfiles: payload => dispatch(getTestProfiles(payload)),
    onAddNewTestProfile: (testProfile, history) => dispatch(addNewTestProfile(testProfile, history)),
    onUpdateTestProfile: (testProfile) => dispatch(updateTestProfile(testProfile)),
    onDeleteTestProfile: testProfile => dispatch(deleteTestProfiles(testProfile)),
    onGetTestProfileDetail: profileId => dispatch(getTestProfileDetail(profileId)),
    onResetTestProfileSearchQuery: () => dispatch(resetTestProfileSearchQuery()),
    onAddNewProfileTest: testProfileGroup => dispatch(addNewProfileTest(testProfileGroup)),
    onGetTestProfileTests: id => dispatch(getTestProfileTests(id)),
    onSaveProfileTests: payload => dispatch(saveProfileTests(payload)),
    onDisplayOrderChange: (payload, callback) => dispatch(saveDisplayOrder(payload, callback)),
    onAddNewSubProfile: testProfileGroup => dispatch(addNewSubProfile(testProfileGroup)),
    onSaveSubProfiles: payload => dispatch(saveTestProfileGroups(payload)),
    onDeleteSubProfiles: testProfileGroups => dispatch(deleteSubProfiles(testProfileGroups)),
    onEditSubProfile: (testProfileGroup) => dispatch(editSubProfile(testProfileGroup)),
    onDeleteProfileVendor: (profile, callback) => dispatch(deleteProfileVendor(profile, callback)),
    updateInfoProfile: (profile, callback) => dispatch(updateInfoProfile(profile, callback)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testProfilePage", "message", "profilePage", "testPage"])(Profiles)))

import { AvForm } from "availity-reactstrap-validation"
import { CustomBootstrapTable, CustomDatePicker } from "components/Common"
import { ModuleIds } from "constant"
import { convertDateFormat } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import FilterForm from "./FilterForm"

const RESOURCE = ModuleIds.Logs

const ConnectorLogTable = ({
    onSelect,
    onSelectAll,
    connectorLog,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    onRowClick,
    t,
    updatedTime,
    onChangeModel
}) => {
    const [data, setData] = useState([])
    const [model, setModel] = useState({
        search: "",
        start: new Date().setDate(new Date().getDate() - 1),
        end: new Date()
    })

    useEffect(() => {
        onChangeModel && onChangeModel(model)
    }, [model])

    const searchQuery = useSelector(state => state.auditLog.searchConnectorQuery)

    function generateRandomColor() {
        let maxVal = 0xFFFFFF;
        let randomNumber = Math.random() * maxVal;
        randomNumber = Math.floor(randomNumber);
        randomNumber = randomNumber.toString(16);
        let randColor = randomNumber.padStart(6, 0);
        return `#${randColor.toUpperCase()}`
    }

    useEffect(() => {
        if (connectorLog) {
            // let thread = connectorLog.map(item => item.thread?.split('-')[0])
            // thread = [...new Set(thread)]
            // thread = thread.map(item => ({ value: item, color: generateRandomColor() }))

            // let threadValue = ' '
            // let position = 1

            // connectorLog.forEach(item => {
            //     const result = thread.find(x => item.thread.includes(x.value))
            //     if (!item.thread.includes(threadValue)) {
            //         position = 1
            //         threadValue = item.thread.split('-')[0]
            //     }
            //     if (result) {
            //         item.color = result.color
            //         item.position = position
            //         position += 1
            //     }
            // })

            setData([...connectorLog])
        }
    }, [connectorLog])

    const onResetHandler = () => {
        const initModel = {
            search: "",
            start: new Date().setDate(new Date().getDate() - 1),
            end: new Date()
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "thread",
            text: "Thread",
            searchable: false,
            sort: true,
            formatter: (cellContent, code, index) => {
                return (
                    <span>{`${cellContent}`}</span>
                )
            }
        },
        {
            dataField: "timestamp",
            text: "Time stamp",
            searchable: false,
            sort: true,
            formatter: (cellContent, code, index) => {
                return (
                    // <span>{convertDateFormat(cellContent, "YYYY-MM-DD HH:mm:ss")}</span>
                    <span>{cellContent && cellContent.substring(0, 19).replaceAll('T', ' ')}</span>
                )
            }
        },
        {
            dataField: "message",
            text: "Message",
            searchable: false,
            sort: false,
            formatter: (cellContent, code, index) => (
                <div
                    onClick={(e) => { onRowClick(e, code) }}
                    style={{
                        width: '300px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        color: 'blue'
                    }}
                >{cellContent}</div>
            ),
        },
        {
            dataField: "level",
            text: "Level",
            searchable: false,
            sort: true
        },
        {
            dataField: "logger",
            text: "Logger",
            searchable: false,
            sort: true
        },
        {
            dataField: "mdc",
            text: "Mdc",
            searchable: false,
            sort: true,
        },

    ]



    return (
        <div className="table-connector-log">
            <CustomBootstrapTable
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                onRefresh={onRefresh}
                filterForm={() => <FilterForm model={model} />}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                searchText={model?.search}
                loading={loading}
                updatedTime={updatedTime}
                isEnableExport={false}
                resource={RESOURCE}
                keyField="id"
                customButtonTable={() =>
                    <AvForm>
                        <div className="d-flex">
                            <div style={{ marginLeft: '5px' }}>
                                <CustomDatePicker
                                    name="connectorStart"
                                    placeholder={"DD/MM/YYYY HH:mm"}
                                    format={"DD/MM/YYYY HH:mm"}
                                    value={model?.start || new Date().setDate(new Date().getDate() - 1)}
                                    enableTime={true}
                                    dateFormat={'Y-m-d H:i'}
                                    onChangeHandler={e => {
                                        if (e.time.length > 0) {
                                          onChangeModel({
                                            start: convertDateFormat(e.time[0], "YYYY-MM-DD HH:mm")
                                          })
                                          setTimeout(() => {
                                            setModel({
                                              ...model,
                                              start: convertDateFormat(e.time[0], "YYYY-MM-DD HH:mm")
                                            })
                                          }, 100)
                                        } else {
                                          onChangeModel({
                                            start: new Date().setDate(new Date().getDate() - 1)
                                          })
                                      }}
                                    }
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            onSearch(model.search)
                                        }
                                    }}
                                />
                            </div><div style={{ marginLeft: '5px' }}>
                                <CustomDatePicker
                                    name="connectorEnd"
                                    placeholder={"DD/MM/YYYY HH:mm"}
                                    format={"DD/MM/YYYY HH:mm"}
                                    value={model?.end || new Date()}
                                    enableTime={true}
                                    dateFormat={'Y-m-d H:i'}
                                    onChangeHandler={e => {
                                        if (e.time.length > 0) {
                                          onChangeModel({
                                            end: convertDateFormat(e.time[0], "YYYY-MM-DD HH:mm")
                                          })
                                          setTimeout(() => {
                                            setModel({
                                              ...model,
                                              end: convertDateFormat(e.time[0], "YYYY-MM-DD HH:mm")
                                            })
                                          }, 100)
                                        } else {
                                          onChangeModel({
                                            end: new Date()
                                          })
                                      }}
                                    }
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            onSearch(model.search)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </AvForm>
                }
            />
        </div>
    )
}

export default withTranslation(["hisConnectorPage", "common"])(ConnectorLogTable)

import { CustomBootstrapTable, CustomButton, CustomModal } from "components/Common"
import {
  getAllTestProfiles,
  getAllTests
} from "helpers/app-backend"
import { selectCheckboxHandler } from "helpers/utilities"
import { useEffect, useState } from "react"
import { ModalBody, ModalFooter } from "reactstrap"

//i18n
import { ModuleIds, TestRequest_Test_Type } from "constant"
import FilterForm from "pages/Laboratory/Tests/FilterForm"
import { withTranslation } from "react-i18next"
import { debounce } from "lodash"
const RESOURCE = ModuleIds.Test

const QuickAddModal = ({
  modal,
  toggle,
  onSubmit,
  TestDefault,
  model,
  setModel,
  resetModel,
  selectedRow,
  t,
}) => {
  const title = t("testProfilePage:QuickAdd")
  const [profiles, setProfiles] = useState([])
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [profilesfilter, setProfilesFilter] = useState([]);
  useEffect(() => {
    fetchProfiles()
  }, [modal, TestDefault])
  useEffect(() => {
    if (modal == false) {
      resetModel && resetModel()
    }
  }, [modal])

  const fetchProfileAndTestCode = async searchQuery => {
    let dataTest = await getAllTests(searchQuery)
    let dataProfile = await getAllTestProfiles(searchQuery)
    let dataTestAndProfile = [...dataTest.data, ...dataProfile.data]
    dataTestAndProfile = dataTestAndProfile?.filter(item => item.type !== 2)
    return dataTestAndProfile
  }
  const fetchProfiles = debounce(async () => {
    setLoading(true);
    const query = {
      ...model,
      inUse: true,
      size: 100,
    };

    const res = await fetchProfileAndTestCode(query);
    const data = [];

    res?.forEach((dataEach) => {
      if (!TestDefault.some((p) => p.testCode === dataEach.testCode)) {
        data.push({ ...dataEach });
      }
    });

    setProfiles(data);
    setLoading(false);
  }, 500);

  useEffect(() => {
    fetchProfiles()
  }, [model])

  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState } = selectCheckboxHandler(rows, row, isSelected)
    setRows(rowsState)
  }

  const onValidSubmit = async () => {
    rows.forEach(r => {
      onSubmit(r)
      TestDefault.push({ r })
    })
    setRows([])
  }

  const onSearch = searchText => {
    setModel({ ...model, search: searchText })
  }

  const onSubmitFilter = (e, values) => {
    setModel({ ...model, ...e })
  }
  useEffect(() => {
    if (selectedRow?.type === TestRequest_Test_Type.PROFILE) {
      const filteredProfiles = profiles?.filter(profile => profile?.type === TestRequest_Test_Type.TEST);
      setProfilesFilter(filteredProfiles);
    } else {
      setProfilesFilter([]);
    }
  }, [selectedRow, profiles]);

  const columns = [
    {
      dataField: "testCode",
      text: t("Code"),
      style: { width: '100px' },
      searchable: true,
      formatter: (cellContent, item, index) => {
        return cellContent ?? item.code
      },
    },
    {
      dataField: "testName",
      text: t("Test"),
      searchable: true,
      formatter: (cellContent, item, index) => {
        return cellContent ?? item.profileName
      },
    },
    {
      dataField: "type",
      text: t("testPage:Type"),
      style: { width: '100px' },
      searchable: true,
      formatter: (cellContent, item, index) => {
        return (
          <>
            {cellContent === TestRequest_Test_Type.TEST && (
              <div className="px-2 d-flex badge-test">
                <span>Test</span>
              </div>
            )}
            {cellContent === TestRequest_Test_Type.PROFILE && (
              <div className="px-2 d-flex badge-profile">
                <span>Profile</span>
              </div>
            )}
            {cellContent === TestRequest_Test_Type.PROFILE_GROUP && (
              <div className="px-2 d-flex badge-group">
                <span>Group</span>
              </div>
            )}
          </>
        )
      },
    },
    {
      dataField: "categoryName",
      text: t("testPage:Category"),
      searchable: true,
    },
  ]
  return (
    <CustomModal modal={modal} title={title} size="xl" onToggle={toggle}>
      <ModalBody style={{ height: 900 }}>
        <CustomBootstrapTable
          style={{ maxHeight: '670px !important' }}
          columns={columns}
          data={selectedRow?.type === TestRequest_Test_Type.PROFILE ? profilesfilter : profiles}
          onSelect={onSelectCheckbox}
          search
          onSearch={onSearch}
          searchText={model.search}
          filterForm={() => <FilterForm model={model} />}
          onSubmitFilter={onSubmitFilter}
          resource={RESOURCE}
          onRefresh={() => {
            fetchProfiles()
          }}
          loading={loading}
          paging={{ dataSize: profiles.length, size: profiles.length }}
          onSizePerPageChange={() => { }}
        />
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("testProfilePage:Add Tests")}
          type="submit"
          color="primary"
          onClick={onValidSubmit}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["testProfilePage", "testPage", "common"])(
  QuickAddModal
)

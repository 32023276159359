import { AvForm } from "availity-reactstrap-validation"
import {
  SystemInformation,
  TextField
} from "components/Common"
import { ModuleIds, textFieldTypes } from "constant/utility"
import { convertDateFormat } from "helpers/utilities"
import { Col, Row } from "reactstrap"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getGeneralSetting } from "store/setting/generalSetting/actions"

const RESOURCE = ModuleIds.TestRequest

const ViewMode = ({
  data: {
    alternateId,
    medicalId,
    requestDate,
    insuranceNumber,
    inPatient,
    emergency,
    physicianId,
    physicianName,

    patientId,
    patientName,

    departmentId,
    departmentName,

    remark,
    diagnosis,
    diagnosisName,
    serviceTypeName,
  },
  data,
  billingTypes,
  t,
  generalSetting,
  onGetGeneralSetting
}) => {
  const [billingTypeList, setBillingTypeList] = useState(null)
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  useEffect(() => {
    if (isEmpty(generalSetting)) {
      onGetGeneralSetting(userInfo?.company);
    }
  }, [])

  useEffect(() => {
    setBillingTypeList(billingTypes)
  }, [billingTypes])

  const displayInPatient = generalSetting.find(item => item.fieldName === 'InPatient')?.fieldValue === 'True';
  const displayEmergency = generalSetting.find(item => item.fieldName === 'Emergency')?.fieldValue === 'True';

  return (
    <AvForm>
      <Row>
        <Col sm="12 px-0">
          <Row className="px-3">
            <Col sm="6 ">
              <TextField
                label={t("Alternate Request ID")}
                textField={alternateId}
                resource={RESOURCE}
                isEdit={true}
              />
            </Col>
            <Col sm="6">
              <TextField
                label={t("PID")}
                textField={patientId}
                resource={RESOURCE}
              />
            </Col>
            <Col sm="6">
              <TextField
                label={t("Insurance Number")}
                textField={insuranceNumber}
                resource={RESOURCE}
              />
            </Col>
            <Col sm="6">
              <TextField
                label={t("Type")}
                textField={serviceTypeName}
                resource={RESOURCE}
              />
            </Col>
            <Col sm="6">
              <TextField
                label={t("Requesting Physician")}
                textField={physicianName}
                resource={RESOURCE}
              />
            </Col>
            <Col sm="6">
              <TextField
                label={t("Request Date")}
                textField={convertDateFormat(requestDate, "DD-MM-YYYY")}
                resource={RESOURCE}
              />
            </Col>
            <Col xs="6">
              <div className="mb-1">
                <TextField
                  label={t("Department")}
                  textField={departmentName}
                  resource={RESOURCE}
                />
              </div>
            </Col>

            <Col xs="6">
              <div className="mb-1">
                <TextField
                  label={t("Diagnosis")}
                  textField={diagnosisName}
                  resource={RESOURCE}
                />
              </div>
            </Col>
            {displayInPatient && <Col sm="6">
              <TextField
                label={t("common:InPatient")}
                checked={inPatient}
                type={textFieldTypes.SWITCH}
                resource={RESOURCE}
              />
            </Col>}
            {displayEmergency && <Col sm="6">
              <TextField
                label={t("common:Emergency")}
                checked={emergency}
                type={textFieldTypes.SWITCH}
                resource={RESOURCE}
                style={{
                  backgroundColor: data.emergency ? '#eb3c27' : '',
                  borderColor: data.emergency ? '#eb3c27' : ''
                }}
              />
            </Col>}
            <Col sm="6">
              <TextField
                label={t("Remark")}
                textField={remark}
                resource={RESOURCE}
              />
            </Col>
          </Row>
        </Col>
        <Row className="mt-3">
          <Col sm="12" className="px-0">
            <SystemInformation t={t} data={data} />
          </Col>
        </Row>
      </Row>
    </AvForm>
  )
}

ViewMode.propTypes = {
  billingTypes: PropTypes.array,
}

const mapStateToProps = ({ testRequest, generalSetting }) => ({
  testRequests: testRequest.testRequests,
  billingTypes: testRequest.billingTypes,
  generalSetting: generalSetting.generalSetting
})

const mapDispatchToProps = dispatch => ({
  onGetGeneralSetting: payload => dispatch(getGeneralSetting(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "common"])(ViewMode)))

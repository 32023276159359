import { CustomButton, CustomSID } from "components/Common"
import { RESULT_STATE_Text } from "constant"
import { getAllDepartments } from "helpers/app-backend"
import { getI18nextLng, getMinuteBetween } from "helpers/utilities"
import { debounce } from "lodash"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom/cjs/react-router-dom.min"
import { Col, Label, Row } from "reactstrap"
import {
  getRequestSampleTrackingDetail,
  getRequestSampleTrackingList,
} from "store/actions"

const TabAllTest = ({
  data,
  onGetRequestSampleTrackingDetail,
  onGetRequestSampleTrackings,
  isSendTest,
  t,
  loading = false,
}) => {
  const [row, setRow] = useState(null)
  const [department, setDepartment] = useState([])

  let lang = getI18nextLng()

  const debouncedApiCall = debounce(item => {
    onGetRequestSampleTrackingDetail({
      requestId: item.requestId,
      sid: item.sid,
      sampleType: item.sampleType,
    })
  }, 300)

  const onClickRow = item => {
    if (item !== row) {
      setRow(item)
      debouncedApiCall(item)
    } else {
      setRow({})
    }
  }

  useEffect(() => {
    getDepartments()
  }, [])

  const onRefreshHandler = () => {
    onGetRequestSampleTrackings({ page: 1, size: 10, isSendTest })
  }

  const getDepartments = async () => {
    const res = await getAllDepartments({ size: 100 })
    let data = []
    data = res.data.map(_item => {
      _item.value = _item.id
      _item.label = lang === "vi" ? _item.name : _item.englishName || _item.name
      return _item
    })
    setDepartment([...data])
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Label style={{ fontWeight: 600 }}>{t("Newest")}</Label>
        <div className="toolbar button-items text-end">
          <CustomButton
            color="secondary"
            size="sm"
            outline
            onClick={onRefreshHandler}
          >
            <i
              className={`fas fa-sync-alt ${
                loading == true ? " loading-spin" : ""
              }`}
            ></i>
          </CustomButton>
        </div>
      </div>
      <div>
        <ul
          className="list-group list-group-flush"
          style={{ overflowX: "hidden", height: "50vh" }}
        >
          {data.map(item => {
            let color = ""
            // if (item.countInprogress === 0 && item.countValid > 0) {
            //   color = "green"
            // } else if (
            //   getMinuteBetween(new Date(), new Date(item.collectionTime)) < item.tat &&
            //   item.countInprogress > 0 &&
            //   item.countValid > 0
            // ) {
            //   color = "#f1b44c"
            // } else if (item.countDone === 0 && item.countValid === 0 && item.countInprogress > 0) {
            //   color = "gray"
            // } else if (getMinuteBetween(new Date(), new Date(item.collectionTime)) > item.tat && item.countInprogress > 0 && item.countDone > 0) {
            //   color = "#dc3545"
            // }
            if (item.state <= RESULT_STATE_Text.InProcess) {
              color = "gray"
            } else if (item.state === RESULT_STATE_Text.Completed || item.state === RESULT_STATE_Text.Confirmed) {
              color = "#f1b44c"
            } else if (item.state >= RESULT_STATE_Text.Validated) {
              color = "green"
            } else {
              color = "gray"
            }
            return (
              <li
                key={item}
                onClick={() => {
                  onClickRow(item)
                }}
                className="list-group-item"
                style={{
                  background: item == row ? "#fff" : "transparent",
                  paddingBottom: 0,
                  paddingTop: 0,
                  border: item == row && "1px solid #ccc",
                }}
              >
                <div>
                  <Row
                    style={{
                      height: 60,
                    }}
                  >
                    <Col lg="1" style={{ marginTop: 30 }}>
                      <div
                        style={{
                          width: 12,
                          height: 12,
                          background: color,
                          borderRadius: "100%",
                        }}
                      ></div>
                    </Col>
                    <Col lg="1" style={{ paddingLeft: 0, top: 10 }}>
                      <div className="avatar-sm mx-auto mb-4">
                        <span
                          className="avatar-title rounded-circle bg-primary bg-soft font-size-24"
                          style={{ width: 30, height: 30, marginTop: 10 }}
                        >
                          <i
                            style={{ fontSize: 21 }}
                            className="mdi mdi-test-tube text-primary"
                          ></i>
                        </span>
                      </div>
                    </Col>
                    <Col lg="7" style={{ marginTop: 15 }}>
                      <div>
                        <p
                          style={{
                            fontSize: 12,
                            fontWeight: 600,
                            marginBottom: 0,
                          }}
                        >
                          SID:
                          <Link
                            to={`/TestRequest/${item.requestId}/view?tab=1`}
                            target="_blank"
                          >
                            <CustomSID textOnly={true} sid={item.sid} />
                            {item.subSID != 0 ? "-" + item.subSID : ""}
                          </Link>
                          {` - ${item.sampleTypeName}`}
                        </p>
                        <p style={{ fontSize: 12, marginBottom: 0 }}>
                          PID:{" "}
                          <Link
                            to={`/Patient/${item.patientId}/view?tab=1`}
                            target="_blank"
                          >
                            {item.patientId
                              ? [
                                  item.patientId,
                                  item.patientName?.trim(),
                                  item.genderName,
                                ]
                                  .filter(Boolean)
                                  .join(" - ")
                              : ""}
                          </Link>
                        </p>
                      </div>
                    </Col>
                    <Col
                      lg="2"
                      style={{
                        marginTop: 18,
                        justifyContent: "right",
                        position: "absolute",
                        right: "10px",
                      }}
                    >
                      {item.emergency && (
                        <span
                          style={{
                            background: "red",
                            padding: "2px 8px",
                            width: "inherit",
                            borderRadius: "20%",
                            color: "white",
                          }}
                        >
                          CC
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

const mapStateToProps = ({ sampleTracking }) => ({})

const mapDispatchToProps = dispatch => ({
  onGetRequestSampleTrackings: payload =>
    dispatch(getRequestSampleTrackingList(payload)),
  onGetRequestSampleTrackingDetail: payload =>
    dispatch(getRequestSampleTrackingDetail(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["testConfig"])(TabAllTest)))

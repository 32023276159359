import { getUrlParamByKey, getUrlParams } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import TestModal from "./../Modal/TestModal"
import TestTable from "./TestTemporaryTable"

import {
    ConfirmModal,
    TitleAndTable,
    WarningModal
} from "components/Common"
import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler,
} from "helpers/utilities"

import {
    addNewTest,
    deleteTests,
    deleteTestVendor,
    getFullTestSuccess,
    getTestDetail,
    getTests,
    resetSearchQuery,
    updateInfoTest,
    updateTest
} from "store/laboratory/tests/actions"

import { ModuleIds } from "constant"
import ProfileModal from "pages/Laboratory/Profiles/Modal/ProfileModal"
import { withTranslation } from "react-i18next"
import { addNewProfileTest, addNewSubProfile, deleteTestProfiles, getNormalRuleByTestCode, getTestProfileDetail, getTestProfiles, getTestProfileTests, saveDisplayOrderTest, saveProfileTests, updateNormalRangeByTestCode, updateTestProfile } from "store/actions"
import QuickAddModal from "pages/Laboratory/Profiles/Modal/QuickAddModal"

const RESOURCE = ModuleIds.Test

const TestTemporary = ({
    tests,
    test,
    testProfiles,
    testProfile,
    paging,
    onGetTests,
    onUpdateTest,
    onDeleteTests,
    onResetTestSearchQuery,
    onGetTestDetail,
    loadingTests,
    updatedTestTime,
    onGetTestsSuccess,
    t,
    onGetNormalRangeByTestCode,
    onDisplayOrderChange,
    onDeleteTestVendor,
    updateInfoTest,
    onGetTestProfiles,
    onUpdateTestProfile,
    onGetTestProfileTests,
    onAddNewProfileTest,
    onAddNewSubProfile,
    onGetTestProfileDetail,
    onDeleteTestProfile,
    onSaveProfileTests,
    onSaveSubProfiles
}) => {
    useEffect(() => {
        onGetTestsSuccess([])
    }, [])
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmVendorModal, setConfirmVendorModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [vendorDelete, setVendorelete] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const [warningVendorModal, setWarningVendorModal] = useState(false)
    const [selectedEditItem, setSelectedEditItem] = useState([])
    const [selectQuickaddModal, setselectQuickaddModal] = useState(false)
    const [modelQuick, setModelQuick] = useState({})
    const [defaultTestProfiles, setDefaultTestProfiles] = useState([])

    const formEl = useRef(null)

    const popStateChange = (event) => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetTestList(params)
        }
        else {
            fetchTests()
        }
    }

    const afterUpdate = () => {
        toggle()
        popStateChange()
    }

    const toggle = () => {
        setModal(prev => !prev)
    }

    const handleValidTestSubmit = (e, values) => {
        const first = values?.testName.indexOf('[')
        const last = values?.testName.lastIndexOf(']')
        let customName = ''
        if (first !== -1 && last !== -1) {
            customName = values?.testName.slice(first + 1, last)
        }
        if (isEdit) {
            const updateTest = {
                ...values,
                testName: values["testName"].replace(`[${customName}]`, '').trimEnd(),
                displayOrder: values["displayOrder"] || 0,
                profiles: values["profiles"] || [],
                customName: customName,
                isNotReady: false
            }
            onUpdateTest({ test: updateTest, callback: afterUpdate })
            onGetNormalRangeByTestCode(values)
        }
    }

    const handleValidSubmit = (e, values, type) => {
        values.type = type
        values.displayOrder = values.displayOrder || 0;
        if (isEdit) {
            values.type = type
            values.status = values.profileStatus
            delete values.profileStatus
            onUpdateTestProfile({ testProfile: values, callback: afterUpdate })
        }
    }
    const onAddTestList = (data) => {
        setselectQuickaddModal(true)
        setSelectedEditItem(data)
        onGetTestProfileTests(data.id)
        setModelQuick({
            search: "",
            category: "",
            type: "",
            sampleType: data?.sampleType
        })
    }

    const onEditHandler = (e, testId, param) => {
        const id = testId || row?.id;
        if (id) {
            setRow(param)
            if (param?.testCode) {
                onGetTestDetail(id)
            } else if (param?.profileName) {
                onGetTestProfileDetail(id)
            }

            setIsEdit(true)
            toggle()
        } else {
            setWarningModal(true)
        }
    }

    const onDeleteVendor = (data) => {
        onDeleteTestVendor([data], () => {
            onRefreshHandler()
            setConfirmVendorModal(state => !state)
        })
    }

    const resetState = () => {
        setRow({})
        setRows([])
    }

    const onDeleteVendorToggleHandler = (test) => {
        setConfirmVendorModal(state => !state)
        setVendorelete(test)
    }

    const onDeleteToggleHandler = (e, test) => {
        onDeleteToggle({
            rows,
            row: test || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete
        })
        if (confirmModal) {
            setRowDelete({})
        }
    }

    const onDeleteMultipleRows = rowsState => {
        if (rowsState[0]?.testCode) {
            onDeleteTests({ tests: rowsState, callback: resetState })
        } else {
            onDeleteTestProfile({ testProfiles: rowsState, callback: resetState })
        }
    }

    const onDeleteSingleRow = rowState => {
        if (rowState[0]?.testCode) {
            onDeleteTests({
                tests: rowState,
                callback: () => {
                    setRows(prev.filter(x => x.id != rowDelete.id))
                }
            })
        } else {
            onDeleteTestProfile({
                testProfiles: rowState,
                callback: () => {
                    setRows(prev => prev.filter(x => x.id !== rowDelete.id))
                },
            })
        }

        setRowDelete({})
        setRow({})
    }

    const onDeleteTestHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        // toggle modal
        setConfirmModal(false)
    }

    /**Get selected row and set to state
    *
    */
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onGetTestList = (payload) => {
        // insertUrlParam(payload)
        payload.isNotReady = true
        payload.page = 1
        payload.size = 0
        onGetTests(payload)
        onGetTestProfiles(payload)
    }

    const fetchTests = () => {
        onGetTestList({ page: 1 })
    }

    const onRefreshHandler = () => {
        resetState();
        popStateChange();
    }

    const onSearch = searchText => {
        onGetTestList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetTestList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetTestList({ page })
    }

    const onSubmitFilter = values => {
        onGetTestList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetTestList({ page: 1, sort: sortString })
    }

    const onDisplayOrderHandler = (item, value) => {
        onDisplayOrderChange({ id: item.id, displayOrder: value }, () => {
            fetchTests()
        })
    }

    /** ---------CYCLE-------- **/
    useEffect(() => {
        onResetTestSearchQuery()
    }, [])

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            params.onGetTestList
            onGetTestList(params)
        } else {
            fetchTests()
        }
    }, [])

    const onUpdateInfoTest = (item) => {
        updateInfoTest(item, () => {
            let params = getUrlParams()
            let paramPage = getUrlParamByKey("page");
            if (paramPage) {
                onGetTestList(params)
            } else {
                fetchTests()
            }
        })
    }

    const handleAddListTestSubmit = async values => {
        if (values.type === 0) {
            const newProfileTest = {
                profileId: Number(selectedEditItem.id),
                testId: values.id,
                testName: values.testName,
                remark: values.remark,
                displayOrder: defaultTestProfiles?.length || 0,
                testCode: values.testCode,
            }
            if (selectQuickaddModal) {
                onAddNewProfileTest({
                    testProfileTest: newProfileTest,
                    callback: onSaveTest,
                })
            }
        }
        if (values.type === 1) {
            const newSubProfile = {
                groupId: Number(selectedEditItem.id),
                profileId: values.id,
                profileName: values.profileName,
                remark: values.remark,
                displayOrder: defaultSubProfiles?.length || 0,
            }
            if (selectQuickaddModal) {
                onAddNewSubProfile({
                    testProfileGroup: newSubProfile,
                    callback: onSaveClick,
                })
            }
        }
    }

    const onSaveClick = data => {
        let newSubProfiles = JSON.parse(JSON.stringify(data))
        newSubProfiles = newSubProfiles.map(field => {
            if (field.new) {
                delete field.new
                delete field.id
            }
            return field
        })
        onSaveSubProfiles({
            profileId: selectedEditItem.id,
            testProfileGroups: newSubProfiles,
        })
    }

    const onSaveTest = (data) => {
        let newProfileTests = JSON.parse(JSON.stringify(data))
        newProfileTests = newProfileTests.map(field => {
            if (field.new) {
                delete field.new
                delete field.id
            }
            return field
        })
        onSaveProfileTests({ profileId: selectedEditItem.id, testProfileTests: newProfileTests })
    }

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <TestTable
                        tests={tests}
                        testProfiles={testProfiles}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={onEditHandler}
                        onDelete={onDeleteToggleHandler}
                        loading={loadingTests}
                        updatedTime={updatedTestTime}
                        onAddTestList={onAddTestList}
                        onDisplayOrderChange={onDisplayOrderHandler}
                        onDeleteVendor={onDeleteVendorToggleHandler}
                        onUpdateTest={onUpdateTest}
                        updateInfoTest={onUpdateInfoTest}
                    />
                )}
                resource={RESOURCE}
                onEdit={onEditHandler}
                onDelete={onDeleteToggleHandler}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("Tests")}
                subtitle={t("Test Pending")}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <WarningModal
                modal={warningVendorModal}
                onToggle={() => {
                    setSelectQuickaddModal(true)
                    setSelectedEditItem(rows)
                    setWarningVendorModal(prev => !prev)
                }}
                message={t("Add Vendor Warning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteTestHandler}
            />
            <ConfirmModal
                modal={confirmVendorModal}
                title={`${t("common:Delete")}`}
                message={t("Delete test send")}
                onToggle={onDeleteVendorToggleHandler}
                onDelete={() => onDeleteVendor(vendorDelete)}
            />
            {row?.testName && <TestModal
                formEl={formEl}
                modal={modal}
                isEdit={isEdit}
                onValidSubmit={handleValidTestSubmit}
                toggle={toggle}
                data={test}
            />}
            {row?.profileName && <ProfileModal
                formEl={formEl}
                modal={modal}
                isEdit={isEdit}
                onValidSubmit={handleValidSubmit}
                toggle={toggle}
                data={testProfile}
            />}

            <QuickAddModal
                modal={selectQuickaddModal}
                toggle={() => setselectQuickaddModal(!selectQuickaddModal)}
                onSubmit={handleAddListTestSubmit}
                model={modelQuick}
                TestDefault={defaultTestProfiles}
                setModel={setModelQuick}
                selectedRow={selectedEditItem}
                resetModel={() => {
                    setModelQuick({
                        search: selectedEditItem?.code,
                        category: "",
                        type: "",
                        sampleType: selectedEditItem?.sampleType
                    })
                }}
            />
        </React.Fragment>
    )
}

TestTemporary.propTypes = {
    tests: PropTypes.array,
    paging: PropTypes.object,
    onGetTests: PropTypes.func,
    onAddNewTest: PropTypes.func,
    onUpdateTest: PropTypes.func,
    onDeleteTests: PropTypes.func,
    onResetTestSearchQuery: PropTypes.func,
    onGetTestDetail: PropTypes.func,
    test: PropTypes.object,
    loadingTests: PropTypes.bool,
    updatedTestTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ test, testProfile }) => ({
    tests: test.tests,
    test: test.test,
    paging: test.paging,
    loadingTests: test.loadingTests,
    updatedTestTime: test.updatedTime,
    testProfileTests: testProfile.testProfileTests,
    testProfiles: testProfile.testProfiles,
    testProfile: testProfile.testProfile
})

const mapDispatchToProps = dispatch => ({
    onGetTests: payload => dispatch(getTests(payload)),
    onAddNewTest: (test, history) => dispatch(addNewTest(test, history)),
    onUpdateTest: test => dispatch(updateTest(test)),
    onDeleteTests: test => dispatch(deleteTests(test)),
    onGetTestDetail: testId => dispatch(getTestDetail(testId)),
    onResetTestSearchQuery: () => dispatch(resetSearchQuery()),
    onGetTestsSuccess: payload => dispatch(getFullTestSuccess(payload)),
    onGetNormalRuleByTestCode: (payload, callback) => dispatch(getNormalRuleByTestCode(payload, callback)),
    onGetNormalRangeByTestCode: (payload, callback) => dispatch(updateNormalRangeByTestCode(payload, callback)),
    onDisplayOrderChange: (payload, callback) => dispatch(saveDisplayOrderTest(payload, callback)),
    onDeleteTestVendor: (test, callback) => dispatch(deleteTestVendor(test, callback)),
    updateInfoTest: (payload, callback) => dispatch(updateInfoTest(payload, callback)),
    onGetTestProfiles: payload => dispatch(getTestProfiles(payload)),
    onUpdateTestProfile: (testProfile) => dispatch(updateTestProfile(testProfile)),
    onGetTestProfileTests: id => dispatch(getTestProfileTests(id)),
    onAddNewProfileTest: testProfileGroup => dispatch(addNewProfileTest(testProfileGroup)),
    onAddNewSubProfile: testProfileGroup => dispatch(addNewSubProfile(testProfileGroup)),
    onGetTestProfileDetail: profileId => dispatch(getTestProfileDetail(profileId)),
    onDeleteTestProfile: testProfile => dispatch(deleteTestProfiles(testProfile)),
    onSaveProfileTests: payload => dispatch(saveProfileTests(payload)),
    onSaveSubProfiles: payload => dispatch(saveTestProfileGroups(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testPage", "testProfilePage", "message"])(TestTemporary)))
import { AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { Label, Row } from "reactstrap"
import { CustomSelect, DatePicker } from "components/Common"
import { parameterCode } from "constant/utility"
import { withTranslation } from "react-i18next"

const FilterForm = ({ model, t }) => {
  return (
    <>
      <Row>
        <div className="mb-3">
          <CustomSelect
            name="instrumentId"
            portal
            value={model.instrumentId || ""}
            code={parameterCode.INSTRUMENT}
            showID
            label={t("Instrument Name")}
            onChange={(name, value, label, item) => {
              model.instrumentId = value[0]
            }}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <CustomSelect
            label={t("Test Code")}
            portal
            name="testCode"
            value={model.testCode || ""}
            code={parameterCode.TESTPROFILE_TEST}
            onChange={(name, value, label, item) => {
              model.testCode = value[0]
            }}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("Downloaded")}?</Label>
          <AvRadioGroup name="download" value={model.download || ""}>
            <AvRadio label={t("Yes")} value="true" />
            <AvRadio label={t("No")} value="false" />
          </AvRadioGroup>
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["workOrderPage", "common"])(FilterForm)

import {
    ConfirmModal2,
    TitleAndTable, WarningModal
} from "components/Common"
import { ModuleIds, ReportAction, ReportResource, TestResult_Submit_Type, parameterCode } from "constant"
import PropTypes from "prop-types"
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
//i18n
import { getCodesByParameterId } from "helpers/app-backend"
import { getTestResultConfigColumn } from "helpers/app-backend/testResult_backend_helper"
import { GetDataUrlReportConfig, getI18nextLng, GetResourceReportIdConfig, NameToSlug } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import {
    ConfirmTestResultResults,
    DeleteTestResultResults,
    InValidTestResultResults, SignTestResultResults, UnConfirmTestResultResults,
    UnsignTestResultResults,
    ValidTestResultResults,
    clearUserValidate,
    getResultColumnsCurrentSettings,
    getResultComment,
    getTestPerformResults, getTestResultDetail, getTestResultResults,
    getUpdatableResultField,
    rerunTestResultResults,
    runRuleTestResultResults,
    setLoadingChangeDetail,
    updateResultColumnsSettings,
    warningRuleTestResultResults
} from "store/laboratory/testResult/actions"
import HeaderButtons from "./HeaderButtons"
import ConCludeModal from "./Modal/ConCludeModal"
import ConfigColumnModal from "./Modal/ConfigColumnModal"
import GeneralHistoryModal from "./Modal/GeneralHistoryModal"
import WarningAlertModal from "./Modal/WarningAlertModal"
import TabResultTable from "./TabResultTable2"
import TestSend from "./Modal/TestSend"

let lang = getI18nextLng()
let isValidAndSend = false
const DetailTestsTab = forwardRef(({
    t,
    loadingResultResults,
    resultResultsTime,
    onRefresh,
    testResultResults,
    onSubmitConfirm,
    onSubmitUnConfirm, onSubmitValid, onSubmitInValid, onSubmitDelete, onSubmitRerunTests,
    id, onGetResultColumnsCurrentSettings, testResultColumns, onUpdateResultColumnsSettings,
    onSubmitRunRuleTests, onGetTestResultDetail, onGetUpdatableResultField,
    testResult,
    testResultFields,
    currentTab,
    onChange,
    onSubmitWarning,
    requestId,
    conclude,
    onGetResultComment,
    onSetLoadingChangeDetail,
    generalSetting,
    onSign,
    onUnsign,
    userValidate,
    RESOURCE = ModuleIds.TestResult,
    onClearUserValidate,
    resourceReport
}, ref) => {
    const { reportInfos } = useSelector(state => ({
        reportInfos: state.Authorization.reportinfo || [],
    }))
    const onChangeValidAll = (val) => {
        let isCheck = document.getElementById("isValidAllResultDetail")
        if (isCheck) {
            isCheck.checked = val
        }
    }
    useEffect(() => {
        onSetLoadingChangeDetail(true)
        onRefresh(id, () => {
            onSetLoadingChangeDetail(false)
        })
        onGetUpdatableResultField()
        onGetResultComment(id)
        onChangeValidAll(false)
    }, [id])

    const [concludeModal, setConcludeModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [isTestPerform, setIsTestPerform] = useState(true)
    const [warningModal, setWarningModal] = useState(false)
    const [configColumnModal, setConfigColumnModal] = useState(false)
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [ids, setIds] = useState([]);
    const [dataColumn, setDataColumn] = useState([]);
    const tableRef = useRef();
    const [loadingPerform, setLoadingPerform] = useState(false);
    const [loadingRefresh, setLoadingRefresh] = useState(false);
    const [parameterFields, setParameterFields] = useState([])
    const [clickTestPerform, setClickTestPerform] = useState(false)
    const [generalHistoryModal, setGeneralHistoryModal] = useState(false)
    const [dataCheckChange, setDataCheckChange] = useState(testResultResults || [])
    const [modalConfirmValid, setModalConfirmValid] = useState(false)
    const [dataConfirmValid, setDataConfirmValid] = useState([])
    const [isConfirm, setIsConfirm] = useState(false)
    const [selectTestSendModal, setSelectTestSendModal] = useState(false)
    const [warningVendorModal, setWarningVendorModal] = useState(false)

    const validWithoutDelivery = generalSetting.find(item => item.fieldName === 'ValidWithoutDelivery')?.fieldValue === 'True';

    useImperativeHandle(ref, () => ({
        GetIsPrintIds: () => {
            return tableRef.current?.GetIsPrintIds() || [];
        },
        GetReportType: () => {
            return tableRef.current?.GetReportType() || [];
        },
        GetSampleType: () => {
            return tableRef.current?.GetSampleType() || [];
        },
        GetReportByCategory: () => {
            return tableRef.current?.GetReportByCategory() || [];
        },
        GetAllIds: () => {
            return tableRef.current?.GetAllIds() || [];
        },
        GetAllIdsHasResult: () => {
            return tableRef.current?.GetAllIdsHasResult() || [];
        },
        GetAllSampleTypeHasResult: () => {
            return tableRef.current?.GetAllSampleTypeHasResult() || [];
        },
        GetAllReportType: () => {
            return tableRef.current?.GetAllReportType() || [];
        },
        GetAllSampleType: () => {
            return tableRef.current?.GetAllSampleType() || [];
        },
        GetAllReportByCategory: () => {
            return tableRef.current?.GetAllReportByCategory() || [];
        },
        GetIsValidIds: () => {
            return tableRef.current?.GetValidIds() || [];
        },
        ReSetColumns: () => {
            getColumns()
        },
        AfterUpdate: () => {
            afterUpdate()
        },
    }));

    const SubmitConfirm = (title, type) => {
        setClickTestPerform(true)
        setType(type)
        setTitle(title)
        switch (type) {
            case TestResult_Submit_Type.ReRun:
                setIds(tableRef.current?.GetRerunIds() || [])
                break;
            case TestResult_Submit_Type.Confirm:
                setIds(tableRef.current?.GetConfirmIds() || [])
                break;
            case TestResult_Submit_Type.UnConfirm:
                setIds(tableRef.current?.GetCancelConfirmIds() || [])
                break;
            case TestResult_Submit_Type.Valid:
                setIds(tableRef.current?.GetValidIds() || [])
                break;
            case TestResult_Submit_Type.InValid:
                setIds(tableRef.current?.GetCancelValidIds() || [])
                break;
            case TestResult_Submit_Type.Delete:
                setIds(tableRef.current?.GetDeleteIds() || [])
                break;
            case TestResult_Submit_Type.WarningRule:
                setIds(tableRef.current?.GetConfirmIds() || [])
                break;
            case TestResult_Submit_Type.RunRule:
                setIds(tableRef.current?.GetConfirmIds() || [])
                break;
            case TestResult_Submit_Type.Unsign:
                setIds(tableRef.current?.GetValidIds() || [])
                break;
            case TestResult_Submit_Type.Sign:
                setIds(tableRef.current?.GetValidIds() || [])
                break;
            default:
                break;
        }
        if (type == TestResult_Submit_Type.ReRun && !isTestPerform) {
        }
        else {
            setConfirmModal(true)
        }
    }
    useEffect(() => {
        if (type == TestResult_Submit_Type.ReRun && !isTestPerform && clickTestPerform == true) {
            SubmitHandler()
        }
    }, [ids])

    const SubmitHandler = () => {
        if (ids.length == 0 && type != TestResult_Submit_Type.Confirm && type != TestResult_Submit_Type.Valid
            && type != TestResult_Submit_Type.Sign
        ) {
            setConfirmModal(false);
            setWarningModal(true)
        }
        else {
            switch (type) {
                case TestResult_Submit_Type.ReRun:
                    onSubmitRerunTests({ ids, id, sid: testResult.sid, callback: afterUpdate })
                    break;
                case TestResult_Submit_Type.Confirm:
                    {
                        let idHasResult = ids;
                        if (ids.length == 0) {
                            idHasResult = tableRef.current?.GetAllIdsHasResult() || [];
                        }
                        if (idHasResult.length == 0) {
                            setConfirmModal(false);
                            setWarningModal(true)
                        }
                        else {
                            onSubmitConfirm({ ids: idHasResult, id, sid: testResult.sid, callback: afterUpdate })
                        }
                        break;
                    }
                case TestResult_Submit_Type.UnConfirm:
                    onSubmitUnConfirm({ ids, id, sid: testResult.sid, callback: () => { afterUpdate(true) } })
                    break;
                case TestResult_Submit_Type.Valid:
                    {
                        let idHasResult = ids;
                        if (ids.length == 0) {
                            idHasResult = tableRef.current?.GetAllIdsHasResult() || [];
                        }
                        if (idHasResult.length == 0) {
                            setConfirmModal(false);
                            setWarningModal(true)
                        }
                        else {
                            const params = {
                                Module: ModuleIds.TestResult,
                                ItemAction: ReportAction.Create,
                                ItemValue: `${id}`,
                                reportParameters: {
                                    ...GetDataUrlReportConfig(reportInfos, ReportResource.TestResult, testResult.fileId),
                                    ResultId: `${testResult.id}`,
                                    hasSignature: "false",
                                    SampleTypes: tableRef.current?.GetAllSampleTypeHasResult() || [],
                                },
                                orderCode: testResult.alternateId,
                                medicalId: testResult.medicalId,
                                patientName: NameToSlug(testResult.patientName)
                            }
                            onSubmitValid({
                                ids: idHasResult, id, sid: testResult.sid, validWithoutDelivery,
                                validatedBy: userValidate,
                                reportId: GetResourceReportIdConfig(reportInfos),
                                params,
                                callback: afterUpdate,
                                isValidAndSend
                            })
                        }
                        break;
                    }
                case TestResult_Submit_Type.InValid:
                    onSubmitInValid({ ids, id, sid: testResult.sid, callback: () => { afterUpdate(true) } })
                    break;
                case TestResult_Submit_Type.Delete:
                    onSubmitDelete({ ids, id, sid: testResult.sid, callback: afterUpdate })
                    break;
                case TestResult_Submit_Type.WarningRule:
                    onSubmitWarning({ ids, id, sid: testResult.sid, callback: afterUpdate })
                    break;
                case TestResult_Submit_Type.RunRule:
                    onSubmitRunRuleTests({ ids, id, sid: testResult.sid, callback: afterUpdate })
                    break;
                case TestResult_Submit_Type.Sign:
                    onSign && onSign()
                    break;
                case TestResult_Submit_Type.Unsign:
                    onUnsign && onUnsign()
                    break;
                default:
                    break;
            }
        }
        setConfirmModal(false);
    }
    useEffect(() => {
        getColumns()
        onClearUserValidate()
    }, [])
    const afterUpdate = (isfocus = false) => {
        onChangeValidAll(false)
        onGetTestResultDetail({
            id: requestId, callback: () => {
                onRefresh(id)
                isfocus && setTimeout(() => {
                    let c = document.getElementsByClassName("resultBlurItemFocus")
                    if (c && c.length > 0) {
                        let parent = c[0].firstElementChild
                        if (parent)
                            parent.firstElementChild.click()
                    }
                }, 1000);

            }
        })
        setClickTestPerform(false)
        setConfirmModal(false)
        setLoadingPerform(false)
        setModalConfirmValid(false)
    }

    const afterUpdateGPB = () => {
        onGetTestResultDetail({
            id: requestId
        })

    }

    const afterRefresh = () => {
        setLoadingRefresh(false)
        onChangeValidAll(false)
    }
    const afterUpdateColumn = () => {
        setConfigColumnModal(false)
        getColumns()
    }
    const getColumns = async () => {
        const res = await getTestResultConfigColumn()
        const fields = await getCodesByParameterId(parameterCode.TEST_RESULT_FIELD_DISPLAY_KEY, lang)
        setParameterFields(fields || [])
        let data = [];
        data = res.map(x => {
            const item = fields.find(z => z.code == x.fieldName)
            return {
                ...x,
                message: item?.message,
                displayLength: x.displayLength > 0 ? x.displayLength : 0
            }
        })
        setDataColumn(data);
        tableRef.current?.ResetColumnWidth()
    }

    useEffect(() => {
        if (modalConfirmValid == true) {
            setDataConfirmValid(tableRef.current?.GetTestSelected() || [])
        }
    }, [modalConfirmValid])

    return (
        <React.Fragment>
            <div className="result-detail-conclude-title d-flex">
                <div>{t("Conclude")}</div>
                <div className="flex-1 d-flex">
                    <div className={(conclude && conclude != '') ? `result-detail-conclude` : ''}>{conclude}</div>
                    <div className="flex-1"></div>
                </div>
            </div>
            <div className="testsTabTable result-detail-table" style={{ height: "calc(100% - 24px)" }}>
                <TitleAndTable
                    resource={RESOURCE}
                    external={true}
                    customButtons={() => (
                        <HeaderButtons
                            data={dataCheckChange || []}
                            dataInfoResult={testResult}
                            resource={RESOURCE}
                            loadingPerform={loadingPerform}
                            loadingRefresh={loadingRefresh}
                            onRefreshClick={() => {
                                setLoadingRefresh(true)
                                onRefresh(id, afterRefresh)
                            }}
                            onSubmitConfirm={() => {
                                setIsConfirm(true)
                                // setModalConfirmValid(true)
                                SubmitConfirm(t("Confirm"), TestResult_Submit_Type.Confirm)
                            }}
                            onSubmitCancelConfirm={() => SubmitConfirm(t("Cancel Confirm"), TestResult_Submit_Type.UnConfirm)}
                            onSubmitValidate={(isSend) => {
                                setIsConfirm(false)
                                //setModalConfirmValid(true)
                                isValidAndSend = isSend == true
                                SubmitConfirm(t("Valid"), TestResult_Submit_Type.Valid)
                            }}
                            onSubmitSign={() => { SubmitConfirm(t("Sign"), TestResult_Submit_Type.Sign) }}
                            onSubmitUnsigned={() => { SubmitConfirm(t("Unsign"), TestResult_Submit_Type.Unsign) }}
                            onFastValidChange={() => { }}
                            onSubmitCancelValidate={() => SubmitConfirm(t("Cancel Valid"), TestResult_Submit_Type.InValid)}
                            onConfigClick={() => { onGetResultColumnsCurrentSettings(); setConfigColumnModal(prev => !prev) }}
                            onPerformClick={() => SubmitConfirm(t("Rerun Tests"), TestResult_Submit_Type.ReRun)}
                            onRunRuleClick={() => SubmitConfirm(t("Run Rule"), TestResult_Submit_Type.RunRule)}
                            onWarningRuleClick={() => SubmitConfirm(t("Warning Rule"), TestResult_Submit_Type.WarningRule)}
                            onDelete={() => SubmitConfirm(t("common:Delete"), TestResult_Submit_Type.Delete)}
                            onHistoryResultClick={() => { setGeneralHistoryModal(prev => !prev) }}
                            onConcludeClick={() => {
                                setConcludeModal(true)
                            }}
                            onTestSend={() => {
                                if (tableRef.current?.GetTestSelected().length === 0) {
                                    setWarningVendorModal(prev => !prev)
                                } else {
                                    setSelectTestSendModal(!selectTestSendModal)
                                }
                            }}
                        // onNotifyClick={() => {
                        //     setNotifyModal(true)
                        // }}
                        />
                    )}
                    table={() => (
                        <TabResultTable
                            ref={tableRef}
                            data={testResultResults || []}
                            loading={loadingResultResults}
                            updatedTime={resultResultsTime}
                            dataColumn={dataColumn}
                            state={testResult.state}
                            testResultFields={testResultFields}
                            parameterFields={parameterFields}
                            resourceReport={resourceReport}
                            onRefresh={() => {
                                onRefresh(id, afterRefresh)
                            }}
                            ChangeTestPerform={val => {
                                setIsTestPerform(val)
                            }}
                            onChange={(vals) => {
                                onChange && onChange(vals)
                                setDataCheckChange(vals)
                            }}
                            RESOURCE={RESOURCE}
                            onReloadDetail={afterUpdateGPB}
                            currentTab={currentTab}
                            resultId={testResult.id}
                        />
                    )}
                    subtitle={t("Result Detail")}
                    icon={false}
                />

            </div>
            <ConfigColumnModal
                modal={configColumnModal}
                data={testResultColumns}
                toggle={() => setConfigColumnModal(prev => !prev)}
                onValidSubmit={(values) => {
                    onUpdateResultColumnsSettings({ columns: values, callback: afterUpdateColumn });
                }}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal2
                modal={confirmModal}
                title={`${title} ${t("Result Short")}`}
                message={`${t("message:ConfirmResult", {
                    field: t(title)
                })}`}
                onToggle={() => setConfirmModal(prev => !prev)}
                onConfirm={() => { SubmitHandler() }}
                btnConfirmText={t("Ok")}
                isEdit={true}
            />

            <GeneralHistoryModal
                patientId={testResult.patientId}
                modal={generalHistoryModal}
                toggle={() => setGeneralHistoryModal(prev => !prev)}
                title={`${t("General Result History")}`}
                individual={testResult}
            />
            <ConCludeModal
                conclude={conclude}
                modal={concludeModal}
                toggle={() => { setConcludeModal(false) }}
                afterSubmit={() => {
                    // setNotifyModal(false)
                }}
            />
            {/* <CreateNotifyModal
                data={testResult}
                modal={notifyModal}
                toggle={() => { setNotifyModal(false) }}
                afterSubmit={() => {
                    setNotifyModal(false)
                }}
            /> */}

            <WarningAlertModal
                data={dataConfirmValid}
                modal={modalConfirmValid}
                toggle={() => { setModalConfirmValid(false) }}
                isConfirm={isConfirm}
                onValidSubmit={(ids) => {
                    setClickTestPerform(true)
                    if (isConfirm) {
                        setType(TestResult_Submit_Type.Confirm)
                        setTitle(t("Confirm"))
                    }
                    else {
                        setType(TestResult_Submit_Type.Valid)
                        setTitle(t("Valid"))
                    }
                    setIds(ids || [])
                    setConfirmModal(true)
                }}
            />
            <TestSend
                modal={selectTestSendModal}
                toggle={(isRefresh = false) => {
                    setSelectTestSendModal(!selectTestSendModal)
                    if (isRefresh == true)
                        onRefresh(id)
                }}
                data={tableRef.current?.GetTestSendSelected() || []}
                onSubmmitSuccessed={() => {
                    onChangeValidAll(false)
                }}
            />
            <WarningModal
                modal={warningVendorModal}
                onToggle={() => {
                    setWarningVendorModal(prev => !prev)
                }}
                message={t("XN Send Warning")}
            />
        </React.Fragment >
    )
})

DetailTestsTab.propTypes = {
    onRefresh: PropTypes.func,
    t: PropTypes.any,
    loadingResultResults: PropTypes.bool,
    resultResultsTime: PropTypes.any,
    testResultResults: PropTypes.array,
}

DetailTestsTab.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
    loadingResultResults: testResult.loadingResultResults,
    resultResultsTime: testResult.resultResultsTime,
    testResultResults: testResult.testResultResults,
    testResultColumns: testResult.testResultColumns,
    testResult: testResult.testResult,
    testResultFields: testResult.testResultFields,
    conclude: testResult.testResult?.resultComment?.finalComment || '',
    loadingResultResults: testResult.loadingResultResults,
    userValidate: testResult.userValidate,
})

const mapDispatchToProps = dispatch => ({
    onGetTestResultDetail: (payload) => dispatch(getTestResultDetail(payload)),
    onRefresh: (id, callback) => dispatch(getTestResultResults({ id, callback })),
    onGetTestPerformResults: payload => dispatch(getTestPerformResults(payload)),
    onSubmitConfirm: payload => dispatch(ConfirmTestResultResults(payload)),
    onSubmitUnConfirm: payload => dispatch(UnConfirmTestResultResults(payload)),
    onSubmitWarning: payload => dispatch(warningRuleTestResultResults(payload)),
    onSubmitValid: payload => dispatch(ValidTestResultResults(payload)),
    onSubmitInValid: payload => dispatch(InValidTestResultResults(payload)),
    onSubmitRerunTests: payload => dispatch(rerunTestResultResults(payload)),
    onSubmitRunRuleTests: payload => dispatch(runRuleTestResultResults(payload)),
    onSubmitDelete: payload => dispatch(DeleteTestResultResults(payload)),
    onGetResultColumnsCurrentSettings: () => dispatch(getResultColumnsCurrentSettings()),
    onUpdateResultColumnsSettings: payload => dispatch(updateResultColumnsSettings(payload)),
    onGetUpdatableResultField: () => dispatch(getUpdatableResultField()),
    onGetResultComment: (id, callback) => dispatch(getResultComment({ id, callback })),
    onSetLoadingChangeDetail: (data) => dispatch(setLoadingChangeDetail(data)),
    onClearUserValidate: (data) => dispatch(clearUserValidate(data)),
})

DetailTestsTab.displayName = 'DetailTestsTab';
export default withTranslation(["testResultPage", "common", "message"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(DetailTestsTab))

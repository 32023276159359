import { Col, Label, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

import { ModuleIds, parameterCode } from "constant/utility"
//i18n
import { withTranslation } from "react-i18next"
import { getInvalidMessageI18n } from "helpers/utilities"
import {
    CustomButton,
    CustomButtonGroup,
    CustomAvField,
    CustomCheckbox,
    CustomSelect,
    CustomTagsInput,
    CustomSelectGroup,
    CustomSelectAsync,
} from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"

//const RESOURCE = ModuleIds.TestDetail

const EditMode = ({ onValidSubmit, data, onCancel, t, isEdit }) => {
    const testName = data?.customName && data.customName !== '' ? `${data.testName} [${data.customName}]` : data.testName
    return (
        <Row>
            <Col sm="12">
                <AvForm onValidSubmit={onValidSubmit} model={data}>
                    <Row>
                        <CustomAvField name="id" type="hidden" value={data.id || ""} />
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="testCode"
                                    type="text"
                                    disabled={true}
                                    required
                                    errorMessage={getInvalidMessageI18n(t, "Manage Code")}
                                    validate={{
                                        required: { value: true },
                                        maxLength: { value: 20 }
                                    }}
                                    value={data.testCode || ""}
                                    label={t("Manage Code")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="testName"
                                    type="text"
                                    required
                                    errorMessage={getInvalidMessageI18n(t, "Test Name")}
                                    validate={{
                                        required: { value: true },
                                        maxLength: { value: 200 }
                                    }}
                                    value={testName || ""}
                                    label={t("Test Name")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-6">
                            <div className="mb-3">
                                <CustomSelectGroup
                                    name="category"
                                    value={data.category || ""}
                                    code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                                    label={t("Category")}
                                    detected={isEdit}
                                    required
                                    errorMessage={getInvalidMessageI18n(t, "Category")}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomSelect
                                    label={t("Report Type")}
                                    portal
                                    name="reportType"
                                    value={data.reportType || ""}
                                    code={parameterCode.TEST_TYPES}
                                    required
                                    errorMessage={getInvalidMessageI18n(t, "Report Type")}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomSelect
                                    label={t("Sample Type")}
                                    portal
                                    name="sampleType"
                                    value={data.sampleType}
                                    code={parameterCode.SAMPLE_TYPES}
                                    required
                                    errorMessage={getInvalidMessageI18n(t, "Sample Type")}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomSelectAsync
                                    name="profiles"
                                    label={t("Profiles")}
                                    code={parameterCode.TESTPROFILE_CODE}
                                    isMulti
                                    value={data.profiles || []}
                                    valueNameNotSearch={data.profileName || ''}
                                    customSearchQuery={{ isId: true }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="displayOrder"
                                    value={data.displayOrder || 0}
                                    label={t("Display Order")}
                                    errorMessage={getInvalidMessageI18n(t, "Display Order")}
                                    validate={{
                                        required: { value: true, errorMessage: getInvalidMessageI18n(t, "Display Order") },
                                        pattern: { value: '^[0-9]+$', errorMessage: getInvalidMessageI18n(t, "Display Order") },
                                    }}
                                    detected={isEdit}
                                    type="number"
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="subSID"
                                    value={data.subSID || ""}
                                    errorMessage={getInvalidMessageI18n(t, "Sub SID")}
                                    label={t("SubSID")}
                                    detected={isEdit}
                                    type='number'
                                    min={10}
                                    max={99}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="defaultValue"
                                    type="text"
                                    value={data.defaultValue || ""}
                                    label={t("testConfig:Default Result")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomSelectAsync
                                    name="testMethod"
                                    label={t("Method Test")}
                                    code={parameterCode.METHOD_TEST}
                                    value={data.testMethod || null}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <Label for={'ISO'}>
                                    {`${t("ISO")}`}
                                </Label>
                                <FormSwitchForm
                                    name="ISO"
                                    label=''
                                    value={data.iso}
                                    style={{ margin: 3 }}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="unit"
                                    type="text"
                                    value={data.unit || ""}
                                    label={t("Unit")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <Label for={'enable'}>
                                    {`${t("Is Active")}`}
                                </Label>
                                <FormSwitchForm
                                    name="inUse"
                                    label=''
                                    value={data.inUse}
                                    style={{ margin: 3 }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <CustomButtonGroup className="justify-content-center">
                                <CustomButton
                                    text={t("common:Cancel")}
                                    type="button"
                                    onClick={onCancel}
                                    className="button-width"
                                />
                                <CustomButton
                                    text={t("common:Save")}
                                    type="submit"
                                    color="primary"
                                    className="save-user button-width"
                                    isEdit
                                />
                            </CustomButtonGroup>
                        </Col>
                    </Row>
                </AvForm>
            </Col>

        </Row>
    )
}

export default withTranslation(["testPage", "common", "message"])(EditMode)

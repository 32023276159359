import { CustomBootstrapInlineTable, CustomButton, CustomPrintButton, CustomSID, FormSwitch } from "components/Common"
import CustomPopover from "components/Common/Input/CustomPopover"
import { InlineEditType, RESULT_STATE_Text, ReportResource, TestRequest_State, parameterCode } from "constant"
import { getAuthorizePage, getFilePrint, pingToPrintService, sendDataPrint } from "helpers/app-backend"
import { GetDataUrlReportConfig, convertDateFormat } from "helpers/utilities"
import { isEmpty } from "lodash"
import * as moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  editTestRequestNumbOfLabels,
  editTestRequestSampleId,
  setRequestSampleClear,
  setRequestSampleCollectTime,
  setRequestSampleCollector,
  setRequestSampleNumbOfLabels,
  setRequestSampleQuality,
  setRequestSampleReceivedTime,
  setRequestSampleReceiver,
  setRequestSampleSID,
  updateNumbOfLabels
} from "store/actions"


const SampleTabTable = ({
  onSelect,
  onSelectAll,
  data,
  dataRequest,
  onEdit,
  onDelete,
  t,
  loading,
  onSetRequestSampleCollector,
  onSetRequestSampleCollectTime,
  onSetRequestSampleClear,
  onSetRequestSampleQuality,
  onSetRequestSampleReceiver,
  onSetRequestSampleReceivedTime,
  onSetRequestSampleSID,
  onSetRequestSampleNumbOfLabels,
  onChange,
  onEditTestRequestSampleId,
  onEditTestRequestNumbOfLabels,
  onUpdateNumberOfLabel,
  generalSetting,
  generalSettingObject,
  testRequest,
  userCollect,
  userCollectName,
  isCollected,
  userReceive,
  userReceiveName,
  isReceived,
  lstQuanlity,
  ...rest
}) => {
  const [subSampleData, setSampleData] = useState([])
  const [reportResultInfos, setReportResultInfos] = useState({})
  const isCancel = (code) => code.state === RESULT_STATE_Text.Canceled ? { textDecoration: 'line-through' } : {}
  const stateConditionCollected = (state, isClear) => {
    if (state != RESULT_STATE_Text.Submitted)
      return false
    return (state <= TestRequest_State.Collected &&
      state != RESULT_STATE_Text.Canceled &&
      isClear) ||
      (state == TestRequest_State.Onhold && isClear)
  }
  const stateConditionReceived = (state, isClear, isCollected, isReceived) =>
    (isReceived &&
      isCollected &&
      state <= TestRequest_State.Received &&
      state !== RESULT_STATE_Text.Canceled &&
      isClear) ||
    (state == TestRequest_State.Onhold && isClear)
  useEffect(() => {
    setSampleData(data.map(x => ({ ...x, isClear: x.isClear !== undefined ? x.isClear : ((x.collectionTime && x.collectorUserId) || (x.receivedTime && x.receiverUserId)), })));
  }, [data])

  const getReportResultInfos = async () => {
    const res = await getAuthorizePage(1, "_TestRequest")
    if (res) {
      setReportResultInfos(res._ReportInfo)
    }
  }

  useEffect(() => {
    getReportResultInfos()
  }, [])

  const displayCollected = generalSetting.find(item => item.fieldName === 'Collected')?.fieldValue === 'True';
  const displayReceived = generalSetting.find(item => item.fieldName === 'Received')?.fieldValue === 'True';

  let widthColumn = 0

  if (displayCollected) {
    widthColumn + 20
  }
  if (displayReceived) {
    widthColumn + 20
  }

  const confirmElement = (
    <button type="submit"
      className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1"
    ><i className="mdi mdi-check"></i></button>
  );

  const cancelElement = (
    <button type="button"
      className="btn btn-outline-primary editable-cancel btn-sm me-3">
      <i className="mdi mdi-close"></i></button>
  );

  const { statusRequest, reportInfos } = useSelector(state => ({
    statusRequest: state.testRequest.testRequest?.state,
    reportInfos: state.Authorization.reportinfo || [],
  }))

  const PrintConfig = async (cell, item, index) => {
    const print = JSON.parse(localStorage.getItem("printConfig"))
    let printColumns = print.columns
    let printPage = ['B1', 'B2', 'B3']
    if (reportResultInfos.length > 0 && !isEmpty(reportResultInfos[0].uri)) {
      const res = await getFilePrint({
        ...GetDataUrlReportConfig(reportResultInfos, ReportResource.RequestId, ""),
        SID: item.sid,
        SubSID: (item.subSID !== '' && item.subSID !== null) ? item.subSID : 0,
        RequestDate: dataRequest.requestDate,
        SampleType: item.sampleType,
        Gender: dataRequest.gender,
        Page: printPage[printColumns - 1]
      })
      try {
        const getPingPrintService = await pingToPrintService();
        if (getPingPrintService.pingTime) {
          const dataSendPrint = await sendDataPrint({
            ...GetDataUrlReportConfig(reportResultInfos, ReportResource.DeliveryId, ""),
            "filePath": `${res.reportUrl}`,
            "quantity": item.numberOfLabels
          })
        } else {
          window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
      }
      catch {
        window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
      }
    }
    else {
      console.log('failed')
    }
  }

  const onPrintBarcodeHandler = async (cell, item, index) => {
    PrintConfig(cell, item, index)
  }

  const columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "idx",
      text: "#",
      searchable: false,
      style: { fontSize: 12 },
      headerStyle: { width: `${widthColumn + 30}px`, fontSize: 12 },
      formatter: (cellContent, item, index) => {
        return index + 1
      },
    },
    {
      dataField: "",
      text: "",
      searchable: false,
      headerStyle: { width: "10px" },
      formatter: (cellContent, item, index) => {
        return (
          <>
            <FormSwitch
              label=""
              value={item.isClear}
              disabled={
                ((subSampleData.length > 1 &&
                  item.state <= TestRequest_State.Collected &&
                  item.state != RESULT_STATE_Text.Canceled) ||
                  (item.state == TestRequest_State.Onhold &&
                    subSampleData.length > 1)) && (item.state == RESULT_STATE_Text.Submitted)
                  ? false
                  : true
              }
              onChangeSwitch={value => {
                subSampleData.map(x => {
                  if (x.sid === item.sid && x.sampleType === item.sampleType) {
                    x.isClear = value
                  }
                })
                let date = moment(new Date()).format("YYYY-MM-DDTHH:mm")
                item.collectionTime = date
                item.collectorUserId = userCollect
                item.collectorName = userCollectName
                item.receivedTime = date
                item.receiverUserId = userReceive
                item.receiverName = userReceiveName
                item.qualityName = lstQuanlity.find(x => x.value == "G")?.label
                onSetRequestSampleClear({ item, value })
                setSampleData([...subSampleData])
                onChange && onChange()
              }}
            />
          </>
        )
      },
    },
    {
      dataField: "",
      text: t("Barcode"),
      align: "center",
      headerAlign: "center",
      headerStyle: { width: "30px", fontSize: 12 },
      formatter: (cellContent, item, index) => {
        return (
          <>
            {item.state != RESULT_STATE_Text.Canceled ? (
              <CustomPrintButton
                name={"Barcode_Template_no4_Other_Report"}
                isPrint={true}
                sid={item.sid}
                subSID={(item.subSID !== '' && item.subSID !== null) ? item.subSID : 0}
                sampleType={item.sampleType}
                requestDate={dataRequest.requestDate}
                reportResource={ReportResource.RequestId}
                fileId={""}
                reportInfos={reportResultInfos}
                gender={dataRequest.gender}
              />
            ) : (
              <span>---</span>
            )}
          </>
        )
      },
    },
    {
      dataField: "sampleTypeName",
      text: t("Sample"),
      style: { padding: "0px", fontSize: 12, textAlign: "center" },
      headerAlign: "center",
      headerStyle: { width: `${widthColumn + 90}px`, fontSize: 12 },
      formatter: (cellContent, item, index) => {
        return <span style={isCancel(item)}>{cellContent}</span>
      },
    },
    {
      dataField: "sampleColor",
      text: "",
      style: { padding: "0px", fontSize: 12, textAlign: "center" },
      headerStyle: { padding: "0px 0px", fontSize: 12, width: "30px" },
      formatter: (cellContent, item, index) =>
        item?.sampleColor && (
          <i style={{ color: item?.sampleColor }} className="fas fa-circle"></i>
        ),
    },
    {
      dataField: "sampleType",
      text: t("Sample Code"),
      style: { padding: "0px", fontSize: 12, textAlign: "center" },
      headerStyle: {
        width: `${widthColumn + 80}px`,
        fontSize: 12,
        textAlign: "center",
      },
      formatter: (cellContent, item, index) => {
        return <span style={isCancel(item)}>{cellContent}</span>
      },
    },
    {
      dataField: "numberOfLabels",
      style: { padding: "0px", fontSize: 12 },
      headerStyle: { width: `${widthColumn + 60}px`, fontSize: 12 },
      text: t("Labels"),
      formatter: (cellContent, item, index) => {
        const idHTML = `requestSample${item.sampleType}`.replace(" ", "")
        return (
          <>
            {item.state !== RESULT_STATE_Text.Canceled ? (
              <div
                id={idHTML + "_"}
                className={"requestSampleItemFocus"}
                tabIndex={9999}
                style={{
                  minWidth: 45,
                  maxWidth: 150,
                  width: "max-content",
                  textAlign: "center",
                }}
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                <Editable
                  alwaysEditing={false}
                  disabled={false}
                  editText={`${item.numberOfLabels || "--"}`}
                  initialValue={`${item.numberOfLabels || "--"}`}
                  id={item.code}
                  isValueClickable={false}
                  mode="inline"
                  placement="top"
                  showText={false}
                  type="textfield"
                  renderConfirmElement={confirmElement}
                  renderCancelElement={cancelElement}
                  onSubmit={value => {
                    onSetRequestSampleNumbOfLabels(
                      { item, value: isNaN(value) ? 3 : Number(value) },
                      () => {
                        onEditTestRequestNumbOfLabels({
                          item,
                          numberOfLabels: Number(value),
                          callback: () => {
                            onChange && onChange()
                          },
                        })
                      }
                    )
                  }}
                />
              </div>
            ) : (
              <span>---</span>
            )}
          </>
        )
      },
    },
    {
      dataField: "sid",
      text: t("SID"),
      style: { textAlign: "center", paddingLeft: "5px" },
      headerStyle: {
        // padding: "10px 0px",
        width: `${widthColumn + 70}px`,
        // textAlign: "center",
      },
      formatter: (cellContent, item, index) => {
        const idHTML = `requestSample${item.sampleType}`.replace(" ", "")
        return (
          <React.Fragment>
            <div
              id={idHTML + "_"}
              className={"requestSampleItemFocus"}
              tabIndex={9999}
              style={{
                minWidth: 45,
                maxWidth: 150,
                width: "max-content",
                textAlign: "center",
              }}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              {!item.sid || !item.state ? (
                <Editable
                  alwaysEditing={false}
                  disabled={false}
                  editText={`${item.sid || "Auto"}`}
                  initialValue={`${item.sid || ""}`}
                  id={item.code}
                  isValueClickable={false}
                  mode="inline"
                  placement="top"
                  showText={false}
                  type="textfield"
                  renderConfirmElement={confirmElement}
                  renderCancelElement={cancelElement}
                  onSubmit={value => {
                    const numberRegex = /^[0-9]*$/;
                    const checkSid = isNaN(value) ? 0 : numberRegex.test(value) ? value : 0;
                    onSetRequestSampleSID(
                      { item, value: checkSid },
                      () => {
                        onEditTestRequestSampleId({
                          item,
                          sid: checkSid,
                          callback: () => {
                            onChange && onChange()
                          },
                        })
                      }
                    )
                  }}
                />
              ) : (
                <span style={isCancel(item)}><CustomSID
                  // sid={cellContent}
                  sid={`${cellContent}${item.subSID ? ` - ${item.subSID}` : ''}`}
                  textOnly={true}
                /></span>
              )}
            </div>
          </React.Fragment>
        )
      },
    },
    // {
    //   dataField: "subSID",
    //   text: t("sub SID"),
    //   style: { textAlign: "center", paddingLeft: "5px" },
    //   headerStyle: {
    //     // padding: "10px 0px",
    //     width: `${widthColumn + 70}px`,
    //     // textAlign: "center",
    //   },
    //   formatter: (cellContent, item, index) => {
    //     return (
    //       <span style={isCancel(item)}>
    //         {cellContent != 0 && cellContent}
    //       </span>
    //     )
    //   },
    // },
    ...(displayCollected
      ? [
        {
          dataField: "collectorName",
          text: t("Collector"),
          headerStyle: {
            width: "180px",
            textAlign: "center",
            fontSize: 12,
          },
          style: {
            padding: "10px 10px",
            fontSize: 12,
            textAlign: "center",
          },
          align: "left",
          headerAlign: "left",
          formatter: (cellContent, item, index) => (
            <React.Fragment>
              {stateConditionCollected(item.state, item.isClear) ? (
                <div onClick={e => e.stopPropagation()}>
                  <CustomPopover
                    isSearch={true}
                    code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
                    title={item.collectorName}
                    valueName={item?.collectorName || ""}
                    value={`${item?.collectorUserId || ""}`}
                    onChange={value => {
                      onSetRequestSampleCollector({ item, value })
                      onChange && onChange()
                    }}
                  />
                </div>
              ) : (
                <span>
                  {item.isCollected &&
                    item.collectorName &&
                    item.isClear &&
                    item.state !== RESULT_STATE_Text.Canceled &&
                    item != RESULT_STATE_Text.Submitted
                    ? item.collectorName
                    : "---"}
                </span>
              )}
            </React.Fragment>
          ),
        },
      ]
      : []),
    ...(displayCollected
      ? [
        {
          dataField: "collectionTime",
          text: t("Collection Time"),
          headerStyle: {
            width: "150px",
            fontSize: 12,
            textAlign: "center",
          },
          style: {
            padding: "10px 0px",
            fontSize: 12,
            textAlign: "center",
          },
          align: "left",
          headerAlign: "left",
          formatter: (cellContent, item, index) => {
            return (
              <>
                <span>
                  {item.isCollected &&
                    item.collectorName &&
                    item.isClear &&
                    item.state !== RESULT_STATE_Text.Canceled
                    ? convertDateFormat(item.collectionTime)
                    : "---"}
                </span>
                {/* {stateConditionCollected(item.state, item.isClear) ? (
                  <div onClick={e => e.stopPropagation()}>
                    <CustomPopover
                      type={"datetime"}
                      code={InlineEditType.TESTREQUEST_SAMPLE_TIME}
                      title={
                        item.collectionTime &&
                        convertDateFormat(item.collectionTime)
                      }
                      valueName={item.collectionTime || ""}
                      value={`${item.collectionTime || ""}`}
                      namepopover="dateTime_collectionTime"
                      onChange={values => {
                        let value = moment(values[0]).format(
                          "YYYY-MM-DDTHH:mm"
                        )
                        onSetRequestSampleCollectTime({ item, value })
                        onChange && onChange()
                      }}
                    />
                  </div>
                ) : (
                  <span>
                    {item.isCollected &&
                      item.collectorName &&
                      item.isClear &&
                      item.state !== RESULT_STATE_Text.Canceled
                      ? convertDateFormat(item.collectionTime)
                      : "---"}
                  </span>
                )} */}
              </>
            )
          },
        },
      ]
      : []),
    ...(displayReceived
      ? [
        {
          dataField: "receiverName",
          text: t("Receiver"),
          headerStyle: {
            width: "180px",
            textAlign: "center",
            fontSize: 12,
          },
          style: {
            padding: "10px 0px",
            fontSize: 12,
            textAlign: "center",
          },
          align: "left",
          headerAlign: "left",
          formatter: (cellContent, item, index) => (
            <React.Fragment>
              {stateConditionReceived(
                item.state,
                item.isClear,
                item.isCollected,
                item.isReceived
              ) ? (
                <div onClick={e => e.stopPropagation()}>
                  <CustomPopover
                    isSearch={true}
                    code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
                    title={item.receiverName || ""}
                    valueName={item?.receiverName || ""}
                    value={`${item?.receiverUserId}`}
                    onChange={value => {
                      onSetRequestSampleReceiver({ item, value })
                      onChange && onChange()
                    }}
                  />
                </div>
              ) : (
                <span>
                  {item.isReceived &&
                    item.receivedTime &&
                    item.isClear &&
                    item.isCollected &&
                    item.state !== RESULT_STATE_Text.Canceled
                    ? item.receivedTime
                    : "---"}{" "}
                </span>
              )}
            </React.Fragment>
          ),
        },
      ]
      : []),
    ...(displayReceived
      ? [
        {
          dataField: "receivedTime",
          text: t("Received Time"),
          headerStyle: {
            width: "150px",
            textAlign: "center",
            fontSize: 12,
          },
          style: {
            padding: "10px 0px",
            fontSize: 12,
            textAlign: "center",
          },
          align: "left",
          headerAlign: "left",
          formatter: (cellContent, item, index) => (
            <React.Fragment>
              {stateConditionReceived(
                item.state,
                item.isClear,
                item.isCollected,
                item.isReceived
              ) ? (
                <div
                  style={{ textAlign: "center" }}
                  onClick={e => e.stopPropagation()}
                >
                  <CustomPopover
                    type={"datetime"}
                    code={InlineEditType.TESTREQUEST_SAMPLE_TIME}
                    title={
                      item.collectionTime &&
                      convertDateFormat(item.receivedTime)
                    }
                    valueName={item?.receivedTime || ""}
                    value={`${item?.receivedTime || ""}`}
                    namepopover="dateTime_receivedTime"
                    onChange={values => {
                      let value = moment(values[0]).format("YYYY-MM-DDTHH:mm")
                      onSetRequestSampleReceivedTime({ item, value })
                      onChange && onChange()
                    }}
                  />
                </div>
              ) : (
                <span>
                  {item.isReceived &&
                    item.receivedTime &&
                    item.isClear &&
                    item.isCollected &&
                    item.state !== RESULT_STATE_Text.Canceled
                    ? convertDateFormat(item.receivedTime)
                    : "---"}
                </span>
              )}
            </React.Fragment>
          ),
        },
      ]
      : []),
    ...(generalSettingObject?.QualityField ? [{
      dataField: "qualityName",
      text: t("Quality"),
      style: { padding: "0px", textAlign: "center", fontSize: 12 },
      headerStyle: {
        textAlign: "center",
        fontSize: 12,
        width: `${widthColumn + 75}px`,
      },
      formatter: (cellContent, item, index) =>
        (item.state <= TestRequest_State.Received &&
          item.isCollected &&
          item.state !== RESULT_STATE_Text.Canceled &&
          item.isClear) ||
          (item.state == TestRequest_State.Onhold && item.isClear) ? (
          <CustomPopover
            code={parameterCode.TESTREQUEST_SAMPLE_QUALITY}
            title={item.qualityName?.trim()}
            valueName={item.qualityName}
            value={`${item.quality}`}
            onChange={value => {
              onSetRequestSampleQuality({ item, value })
              onChange && onChange()
            }}
          />
        ) : (
          <span>
            {item.qualityName &&
              item.isClear &&
              item.isCollected &&
              item.state !== RESULT_STATE_Text.Canceled
              ? item.qualityName
              : "---"}
          </span>
        ),
    }] : []),
    {
      dataField: "",
      text: t("Print"),
      style: { textAlign: "center" },
      headerStyle: {
        textAlign: "center",
        fontSize: 12,
        width: `${widthColumn + 75}px`,
      },
      formatter: (cell, item, index) => (
        <CustomButton
          text={t("common:Print")}
          type="submit"
          color="success"
          className="save-user button-width"
          disabled={item.state === RESULT_STATE_Text.Canceled}
          onClick={() => {
            onPrintBarcodeHandler(cell, item, index)
          }}
        >
          <i className="fa fa-print" style={{ marginRight: 8 }}></i>
          {t("common:Print")}
        </CustomButton>
      ),
    },
  ]

  return (
    <div className="table-expand-custom table-delivery">
      <CustomBootstrapInlineTable
        columns={columns}
        data={subSampleData}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        isScrollable
        loading={loading}
        searchText={""}
        draggable
        keyField={"sampleType"}
        isEnableLoadInfo={true}
        {...rest}
      />
    </div>
  )
}

SampleTabTable.propTypes = {
  onSetRequestSampleCollector: PropTypes.func,
  onSetRequestSampleCollectTime: PropTypes.func,
  onSetRequestSampleQuality: PropTypes.func,
  onSetRequestSampleClear: PropTypes.func,
}

const mapStateToProps = ({ testRequest, generalSetting }) => ({
  generalSetting: generalSetting.generalSetting,
  generalSettingObject: generalSetting.generalSettingObject,
  testRequest: testRequest.testRequest,
})

const mapDispatchToProps = dispatch => ({
  onSetRequestSampleCollector: (payload) => dispatch(setRequestSampleCollector(payload)),
  onSetRequestSampleClear: payload => dispatch(setRequestSampleClear(payload)),
  onSetRequestSampleSID: (payload, callback) => dispatch(setRequestSampleSID(payload, callback)),
  onSetRequestSampleNumbOfLabels: (payload, callback) => dispatch(setRequestSampleNumbOfLabels(payload, callback)),
  onSetRequestSampleCollectTime: (payload) => dispatch(setRequestSampleCollectTime(payload)),
  onSetRequestSampleQuality: (payload) => dispatch(setRequestSampleQuality(payload)),
  onSetRequestSampleReceiver: (payload) => dispatch(setRequestSampleReceiver(payload)),
  onSetRequestSampleReceivedTime: (payload) => dispatch(setRequestSampleReceivedTime(payload)),
  onEditTestRequestSampleId: payload => dispatch(editTestRequestSampleId(payload)),
  onEditTestRequestNumbOfLabels: payload => dispatch(editTestRequestNumbOfLabels(payload)),
  onUpdateNumberOfLabel: payload => dispatch(updateNumbOfLabels(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "common"])(SampleTabTable)))